import { Vehicle, VehiclesForTableau, EVehicleStatus } from './../../../data/vehicles.data';
import { ParkingPosition } from './../../../data/building.data';
import RestService from '../../../services/rest.service';
import { EFE2Features, UserAccount } from '../../../data/account.data';
import VehicleService from '../../../services/vehicle.service';
import { ILocationService } from 'angular';
import PrivilegeService from '../../../services/privilege.service';
import {RolePrivilege} from '../../../data/privileges.enum';
import * as angular from "angular";
import AccountService from '../../../services/account.service';
import { VehicleModes } from '../../modals/wache/vehicle.modal/vehicle.modal';
'use strict';

require('./wache.view.component.css');

/* @ngInject */
export default class WacheController {
  public $location: ILocationService;
  public $log: angular.ILogService;
  public $window: angular.IWindowService;
  public $http: angular.IHttpService;
  public $rootScope: angular.IRootScopeService;
  public $scope: angular.IScope;
  public dataService: any;
  public restService: RestService;
  public helperService: any;
  public $uibModal: any;
  public Notification: any;
  public $translate: any;
  public vehicleIds: string[];
  public listeners: any[];
  public parkingPositions: ParkingPosition[] = [];
  public activeTab: WacheTabs = WacheTabs.VEHICLES;
  public account: UserAccount;
  public isShiftBookActive: boolean;
  public vehiclesWithStatus: VehiclesForTableau;
  public statusColorMapping: Map<EVehicleStatus, string>;
  public statusTextColorMapping: Map<EVehicleStatus, string>;
  public statusTranslationMapping: Map<EVehicleStatus, string>;
  public vehicleService: VehicleService;
  public soundActive: boolean = true;
  public displayStatusNumbers: boolean = false;
  public isLoading: boolean = false;
  public hasVehicles: boolean = false;
  public limit = 50;
  public searchFilter: string = '';
  public previousSearchFilter: string = '';
  public vehicleData = {
    currentPage: 1,
    totalElements: 0,
    data: undefined
  } as VehicleDataPage;
  public FileUploader: any;
  public vehicleTrackingFeatureEnabled: boolean = false;

  public highlightAssigned: boolean = false;
  public hideHiddenVehicles: boolean = false;

  constructor($rootScope: angular.IRootScopeService, $scope: angular.IScope, $window: angular.IWindowService, $http: angular.IHttpService, $log: angular.ILogService,
    restService: RestService, $location: ILocationService, helperService, dataService,
    public accountService: AccountService, $uibModal, Notification, $translate, vehicleService: VehicleService, FileUploader,
    public privilegeService: PrivilegeService) {
    this.$log = $log;
    this.$location = $location;
    this.$log.debug('WacheController started...');
    this.$window = $window;
    this.$http = $http;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.dataService = dataService;
    this.restService = restService;
    this.helperService = helperService;
    this.$uibModal = $uibModal;
    this.Notification = Notification;
    this.$translate = $translate;
    this.vehicleIds = [];
    this.vehicleService = vehicleService;
    this.statusColorMapping = new Map<EVehicleStatus, string>();
    this.statusTextColorMapping = new Map<EVehicleStatus, string>();
    this.listeners = [];
    this.FileUploader = FileUploader;


    this.initListeners();

    if (this.dataService.hasAccount()) {
      this.init();
    }
    this.displayStatusNumbers = this.helperService.getFromStorage('tableauDisplayStatus', false);
    this.highlightAssigned = this.helperService.getFromStorage('highlightAssigned', false);
    this.hideHiddenVehicles = this.helperService.getFromStorage('hideHiddenVehicles', false);

    this.$rootScope.$emit('statusNumber.statusTableau', this.displayStatusNumbers);

    const selectedTab = this.$location.search().tab;
    if (selectedTab) {
      this.activeTab = selectedTab as WacheTabs;
    }
  }

  /**
   * Choose the right tab from current privileges
   * @returns
   */
  private chooseTabFromPrivilege(): WacheTabs {
    if (this.privilegeService.has(RolePrivilege.Station_Vehicles)) {
      return WacheTabs.VEHICLES;
    }
    if (this.privilegeService.has(RolePrivilege.Station_Sirens)) {
      return WacheTabs.SIRENS;
    } else if (this.privilegeService.has(RolePrivilege.Station_Status) || this.privilegeService.has(RolePrivilege.Station_Sirens_Status)) {
      return WacheTabs.STATUS;
    } else if (this.privilegeService.has(RolePrivilege.Station_Tableau_AccessSingleGrid)) {
      return WacheTabs.TABLEAU;
    } else if (this.privilegeService.has(RolePrivilege.Station_Parking)) {
      return WacheTabs.PARKING;
    } else if (this.privilegeService.has(RolePrivilege.Station_History)) {
      return WacheTabs.HISTORY;
    } else if (this.privilegeService.has(RolePrivilege.Announcements)) {
      return WacheTabs.ANNOUNCEMENTS;
    } else if (this.privilegeService.has(RolePrivilege.AAO)) {
      return WacheTabs.AAO;
    } else if ((this.privilegeService.has(RolePrivilege.Admin_Relais) || this.privilegeService.has(RolePrivilege.Station_Relais))) {
      return WacheTabs.RELAIS;
    } else if ((this.privilegeService.has(RolePrivilege.Station_Shiftbook))) {
      return WacheTabs.WACHE;
    } else if ((this.privilegeService.has(RolePrivilege.Station_Environment))) {
      return WacheTabs.META;
    }
    return undefined;
  }

  private checkTabPermissions(selectedTab: WacheTabs): WacheTabs {

    if (selectedTab === WacheTabs.WACHE) {
      if (this.privilegeService.has(RolePrivilege.Station_Shiftbook) && this.isShiftBookActive) {
        return WacheTabs.WACHE;
      } else {
        if (this.privilegeService.has(RolePrivilege.Station_Vehicles)) {
          return WacheTabs.VEHICLES
        } else if (this.privilegeService.has(RolePrivilege.Station_Sirens)) {
          return WacheTabs.SIRENS
        } else {
          // should never happen, because this component is not called when those privileges are not set but just in case:
          this.$rootScope.$emit('tab.select.home');
          this.$location.path('/main/home');
        }
      }
    } else {
      if (selectedTab !== WacheTabs.META) {
        if (selectedTab === WacheTabs.VEHICLES && this.privilegeService.has(RolePrivilege.Station_Vehicles)) {
          return WacheTabs.VEHICLES;
        } else if (selectedTab === WacheTabs.SIRENS && this.privilegeService.has(RolePrivilege.Station_Sirens)) {
          return WacheTabs.SIRENS;
        } else if (selectedTab === WacheTabs.STATUS && (this.privilegeService.has(RolePrivilege.Station_Status) || this.privilegeService.has(RolePrivilege.Station_Sirens_Status))) {
          return WacheTabs.STATUS;
        } else if (selectedTab === WacheTabs.TABLEAU && this.privilegeService.has(RolePrivilege.Station_Tableau_AccessSingleGrid)) {
          return WacheTabs.TABLEAU;
        } else if (selectedTab === WacheTabs.PARKING && this.privilegeService.has(RolePrivilege.Station_Parking)) {
          return WacheTabs.PARKING;
        } else if (selectedTab === WacheTabs.HISTORY && this.privilegeService.has(RolePrivilege.Station_History)) {
          return WacheTabs.HISTORY;
        } else if (selectedTab === WacheTabs.ANNOUNCEMENTS && this.privilegeService.has(RolePrivilege.Announcements)) {
          return WacheTabs.ANNOUNCEMENTS;
        } else if (selectedTab === WacheTabs.AAO && this.privilegeService.has(RolePrivilege.AAO)) {
          return WacheTabs.AAO;
        } else if (selectedTab === WacheTabs.RELAIS && (this.privilegeService.has(RolePrivilege.Admin_Relais) || this.privilegeService.has(RolePrivilege.Station_Relais))) {
          return WacheTabs.RELAIS;
        } else if (selectedTab === WacheTabs.GATES && this.privilegeService.has(RolePrivilege.Admin_ParkingPositions)) {
          return WacheTabs.GATES;
        } else if (selectedTab === WacheTabs.MAP && this.privilegeService.has(RolePrivilege.Station_Vehicles_Location)) {
          return WacheTabs.MAP;
        } else if (selectedTab === WacheTabs.DEVICES && this.privilegeService.has(RolePrivilege.Devices)) {
          return WacheTabs.DEVICES;
        }
      }
      if (this.privilegeService.has(RolePrivilege.Station_Environment)) {
        return WacheTabs.META;
      }
      return undefined;

    }
  }

  calculateActiveTab(): WacheTabs {
    let selectedTab = this.$location.search()['tab'];
    if (!selectedTab) {
      selectedTab = WacheTabs.VEHICLES;
    }
    return this.checkTabPermissions(selectedTab);
  }

  toggleHighlightAssigned() {
    this.highlightAssigned = !this.highlightAssigned;
    this.helperService.saveInStorage("highlightAssigned", this.highlightAssigned);
  }

  toggleHideHiddenVehicles() {
    this.hideHiddenVehicles = !this.hideHiddenVehicles;
    this.helperService.saveInStorage("hideHiddenVehicles", this.hideHiddenVehicles);
  }

  init() {
    this.isShiftBookActive = this.accountService.isFeatureEnabled(EFE2Features.SHIFT_BOOK);
    this.account = this.dataService.getAccount();


    this.vehicleTrackingFeatureEnabled = this.accountService.isFeatureEnabled(EFE2Features.VEHICLE_TRACKING);

    if (this.privilegeService.has(RolePrivilege.Station_Vehicles) || this.privilegeService.has(RolePrivilege.Station_Sirens)) {
      this.vehicleService.getStatusColorMapping().then(data => {
        this.statusColorMapping = data;
        this.vehicleService.getStatusTranslationMapping().then(translations => {
          this.statusTranslationMapping = translations;
          this.vehicleService.getStatusTextColorMapping().then(textColorData => {
            this.statusTextColorMapping = textColorData;
          }).finally(() => {
            this.$scope.$applyAsync();
            this.selectTab(this.calculateActiveTab());
          });
        })
      }).catch(error => {
        this.$log.error(error);
        this.$scope.$applyAsync();
        this.selectTab(this.calculateActiveTab());
      });

      if (this.privilegeService.has(RolePrivilege.Station_Parking)) {
        this.loadParkingPositions();
      }

    } else {
      this.selectTab(this.calculateActiveTab());
    }
  };

  /**
   * Load all vehicles and parking positions
   */
  loadParkingPositions() {
    this.dataService.getParkingPositions(true, (parkingPositions: ParkingPosition[]) => {
      this.$log.info('Parking positions: ' + parkingPositions.length);
      this.parkingPositions = parkingPositions;
    }, (response) => {
      //Error occured
      this.$log.error(response);
    });
  }

  /**
   * @deprecated
   * Should not be used anymore
   */
  loadAllVehicles() {
    this.isLoading = true;
    this.restService.loadVehicles((vehicles: Vehicle[]) => {
      this.vehiclesWithStatus = this.sortForStatus(vehicles);
      this.$log.info('Vehicles: ' + this.vehiclesWithStatus.all.length);
      this.isLoading = false;
    }, (response) => {
      //Error occured
      this.$log.error(response);
      this.isLoading = false;
    });
  }

  /**
   * Sort all vehicles for their status
   * @param {*} vehicles
   */
  sortForStatus(vehicles: Vehicle[]): VehiclesForTableau {
    var dataModelForOldStatusTableau = {
      all: vehicles,
      STATUS_1: [],
      STATUS_2: [],
      STATUS_3: [],
      STATUS_4: [],
      STATUS_6: [],
      misc: []
    } as VehiclesForTableau;

    for (var i = 0; i < vehicles.length; i++) {
      var v = vehicles[i];
      this.vehicleIds.push(v.id);
      var status = v.status;

      switch (status) {
        case EVehicleStatus.STATUS_1:
        case EVehicleStatus.STATUS_2:
        case EVehicleStatus.STATUS_3:
        case EVehicleStatus.STATUS_4:
        case EVehicleStatus.STATUS_6:
          dataModelForOldStatusTableau[status].push(v);
          break;
        default:
          dataModelForOldStatusTableau['misc'].push(v);
          break;
      }
    }
    return dataModelForOldStatusTableau;
  }

  /**
   * Init event listeners
   */
  initListeners() {

    this.listeners.push(this.$rootScope.$on('status.change', (event, data) => {
      this.$log.info('Status changed event');
      if (this.vehiclesWithStatus && this.vehiclesWithStatus.all) {
        this.vehiclesWithStatus = this.sortForStatus(this.vehiclesWithStatus.all);
        this.$scope.$evalAsync();
      }
    }));

    this.listeners.push(this.$rootScope.$on('tab.change.wache', (event, data: WacheTabs) => {
      switch (data) {
        case WacheTabs.WACHE:
        case WacheTabs.VEHICLES:
        case WacheTabs.SIRENS:
        case WacheTabs.STATUS:
        case WacheTabs.TABLEAU:
        case WacheTabs.MAP:
        case WacheTabs.PARKING:
        case WacheTabs.HISTORY:
        case WacheTabs.META:
        case WacheTabs.ANNOUNCEMENTS:
        case WacheTabs.RELAIS:
        case WacheTabs.AAO:
        case WacheTabs.GATES:
        case WacheTabs.DEVICES:
          this.selectTab(data);
        default: break;
      }

    }));
    //Wait for new account
    this.listeners.push(this.$rootScope.$on('new.account', () => {
      //Init controller
      this.init();
    }));

    //Wait for LOGOUT
    this.listeners.push(this.$rootScope.$on('delete.account', () => {
      this.account = undefined;
    }));

    // Update vehicle list
    this.listeners.push(this.$rootScope.$on('update.vehicles', () => {
      this.pageChanged();
    }));

    // Update parking position list
    this.listeners.push(this.$rootScope.$on('update.parking', () => {
      this.loadParkingPositions();
    }));

    (this.$rootScope as any).$eventToObservable('search.vehicles').debounce(800).subscribe((val) => {
      this.pageChanged();
    });

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }



  exportFaultsAsPdf() {

    this.$window.open(this.restService.getBaseUrl() +
      '/vehicles/faults/export?Authorization=' + this.$http.defaults.headers.common.Authorization, '_blank');
  }

  refreshStatusTableau() {
    this.$rootScope.$emit('refresh.statusTableau');
  }

  /**
   * Toggle the sound for status tableau
   */
  toggleSounds() {
    this.soundActive = !this.soundActive;
    this.$rootScope.$emit('sound.statusTableau', this.soundActive);
  }

  /**
   * Toggle the status as number for status tableau
   */
  toggleStatusAsNumbers() {
    this.displayStatusNumbers = !this.displayStatusNumbers;
    this.helperService.saveInStorage("tableauDisplayStatus", this.displayStatusNumbers);
    this.$rootScope.$emit('statusNumber.statusTableau', this.displayStatusNumbers);
  }

  /**
   *  Returns true if the current tab is selected
   * @param tab
   */
  isActive(tab: WacheTabs): boolean {
    return this.activeTab === tab;
  }

  /**
   * Select a tab and trigger some loading events
   * @param tabToCheck
   */
  selectTab(tabToCheck: WacheTabs) {

    this.$log.info("Checking tab permission: " + tabToCheck);
    const selectedTab = this.checkTabPermissions(tabToCheck);
    this.$log.info("Selecting tab: " + selectedTab);
    if (!selectedTab) {
      this.activeTab = this.chooseTabFromPrivilege();
    } else {
      this.activeTab = selectedTab;
    }
    if (selectedTab !== WacheTabs.TABLEAU) {
      this.$location.search('group', null)
    }
    this.$location.search('tab', selectedTab);

    switch (this.activeTab) {
      case WacheTabs.VEHICLES:
        this.vehicleData = {
          currentPage: 1,
          totalElements: 0,
          data: undefined
        } as VehicleDataPage;

        this.pageChanged();
        break;
      case WacheTabs.PARKING:
        this.loadParkingPositions();
        break;
      case WacheTabs.STATUS:
        this.loadAllVehicles();
        break;
    }
    this.$rootScope.$emit('update.selectedTabWache', selectedTab);
    this.$scope.$applyAsync();
  }

  update() {
    this.resetSearchFilter();
    this.refreshStatusTableau();
  }

  resetSearchFilter() {
    this.searchFilter = '';
    this.previousSearchFilter = '';
    this.vehicleData.currentPage = 1;
    this.isLoading = true;
    this.onSearchFilterChanged();
  }

  onSearchFilterChanged() {
    this.$rootScope.$emit('search.vehicles');
  }

  pageChanged(fromPageSelection?: boolean) {
    let searchPage = this.vehicleData.currentPage - 1;

    // Check filtering only if page selection did not change
    if (!fromPageSelection) {
      if (this.searchFilter !== this.previousSearchFilter) {
        /* when seach filter is different from previous we reset page
        */
       searchPage = 0;
       // save the current search filter
       this.previousSearchFilter = this.searchFilter;
      } else if (this.searchFilter.length > 0) {
        this.$log.info('Suchfilter hat sich nicht geÃ¤ndert');
      }
    }
    this.isLoading = true;
    this.restService.loadVehiclesPaginated(searchPage, this.limit, this.searchFilter, 'ASC', (response) => {
      this.isLoading = false;
      this.vehicleData = response;
      this.vehicleData.data = this.sortForStatus(response.data);
      this.vehicleData.currentPage = this.vehicleData.currentPage + 1
      this.hasVehicles = this.vehicleData.totalElements !== 0; // always needs to be true

      if (this.searchFilter) {
        // Set focus to input
        setTimeout(() => document.getElementById('wache-vehicle-search-input').focus(), 200);
      }

      this.$log.debug(`Filtered ${this.vehicleData.totalElements} vehicles!`);
    }, (response) => {
      //Error occured
      this.isLoading = false;
      this.$log.error(response);
    });
  }

  /**
   * Open the swap vehicles modal
   */
  openSwapModal() {

    this.$uibModal.open({
      template: require('../../modals/wache/swap.vehicles.modal/swap.modal.html'),
      controller: 'VehicleSwapModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        vehicle: undefined,
        okFunction: () => {
          return () => {
            this.pageChanged();
          }
        }
      }
    });
  }

  /**
 * Open the users swap history
 */
  openSwapHistoryModal() {
    this.$uibModal.open({
      template: require('../../modals/wache/swap.history.modal/swap.history.html'),
      controller: 'VehicleSwapHistoryModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg'
    });
  }

  addVehicle() {
    this.$uibModal.open({
      template: require('../../modals/wache/add.vehicle.modal/add.vehicle.modal.html'),
      controller: 'AddVehicleController',
      backdrop: 'static',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        okFunction: () => {
          return (name: string, code: string) => {
            this.isLoading = true;
            this.restService.addVehicle(name, code).then(vehicle => {
              this.$uibModal.open({
                template: require('../../modals/wache/vehicle.modal/vehicle.modal.html'),
                controller: 'VehicleModalController',
                controllerAs: 'ctrl',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                  vehicle: () => {
                    return vehicle;
                  },
                  vehicleId: () => {
                    return vehicle.id;
                  },
                  okFunction: () => {
                    return (savedVehicle: Vehicle) => {
                      if (angular.isDefined(savedVehicle)) {
                        this.pageChanged();
                      }
                    };
                  },
                  mode: () => VehicleModes.OVERVIEW
                }
              });

            }).then(() => {
              this.pageChanged();
            }).catch(error => {
              this.$log.error(error);
              this.isLoading = false;

            });
          }
        }
      }
    });
  }

  exportVehicles(){
    this.$window.open(
      this.restService.getBaseUrl() + '/vehicles/download?Authorization='+
      this.$http.defaults.headers.common.Authorization,
      '_blank'
    );

  }

  importVehicles(){
    var modalInstance = this.$uibModal.open({
      template: require('../../modals/wache/import.vehicle.modal/import.vehicle.modal.html'),
      controller: 'ImportVehicleModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        uploader: () => {
          let fileUploader = new this.FileUploader();
          return fileUploader
        }
      }
    });
    modalInstance.result.then(() =>{
      this.pageChanged()
      this.$rootScope.$emit('imported.vehicles');
    });

  }

  addParkingPosition() {
    this.restService.addParkingPosition()
      .then(() => this.loadParkingPositions())
      .catch(error => this.$log.error(error));
  }
}

export enum WacheTabs {
  WACHE = 'WACHE',
  VEHICLES = 'VEHICLES',
  DEVICES = 'DEVICES',
  STATUS = 'STATUS',
  TABLEAU = 'TABLEAU',
  HISTORY = 'HISTORY',
  PARKING = 'PARKING',
  META = 'META',
  ANNOUNCEMENTS = 'ANNOUNCEMENTS',
  AAO = 'AAO',
  RELAIS = 'RELAIS',
  GATES = 'GATES',
  MAP = 'MAP',
  SIRENS = 'SIRENS'
}
interface VehicleDataPage {
  length: number,
  currentPage: number,
  totalPages: number,
  totalElements: number,
  data: VehiclesForTableau
}