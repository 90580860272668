'use strict';

import * as L from 'leaflet';
import angular = require('angular');

export default class ObjectMapComponent {
	public restrict: any;
	public template: any;
	public scope: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.map.component.html');
    this.scope = {
      alarmObject: '=',
      mode: '=',
      isEditable: '='
    };
    this.controller = ObjectMapComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectMapComponentController {
	public $log: any;
	public $scope: any;
	public $rootScope: any;
	public helperService: any;
	public restService: any;
	public mapService: any;
	public coords: any;
	public isEditMode: any;
	public markers: any;
	public paths: any;
	public map: any;
	public leafletData: any;
	public listeners: any;
	public center: any;
	public events: any;
	public mode: any;
	public alarmObject: any;

  constructor($log, $scope, $rootScope, helperService, mapService, restService, leafletData) {
    this.$log = $log;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.helperService = helperService;
    this.restService = restService;
    this.mapService = mapService;

    this.coords = undefined;
    this.isEditMode = false;
    //Init map
    this.markers = new Array();
    this.paths = new Array();
    this.map = undefined;
    this.leafletData = leafletData;
    this.listeners = new Array();

    this.center = {
      lat: 50.875,
      lng: 9.909,
      zoom: 5
    };
    this.events = {};

    this.leafletData.getMap('mymap').then((map) => {
      this.map = map;
      map.attributionControl.setPrefix('<a style="color:black !important" href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a>');
      this.initLayers(map);
      this.map.invalidateSize();
    });

    this.listeners.push(this.$scope.$watch('ctrl.mode', () => {
      if (this.mode === 'MAP') {
        this.showObjectOnMap();
      }
    }));

    this.listeners.push(this.$rootScope.$on('alarm.object.map.show', (event, data) => {
      if (data.type === 'OVERALL') {
        this.showObjectOnMap();
        this.isEditMode = false;
        return;
      } else {
        if (angular.isUndefined(data.coords)) {
          data.coords = {};
        }
        this.coords = data.coords;
        //Show marker on map
        this.isEditMode = true;
      }
      this.updateMarkerOnMap();

      if (angular.isDefined(this.coords) && angular.isDefined(this.coords.lat)) {
        this.center = {
          lat: this.coords.lat,
          lng: this.coords.lng,
          zoom: 18
        };
      }
    }));


    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });

  }

  initLayers(map) {
    // we have to remove the default OSM layer
    map.eachLayer(layer => {
      // only remove the default layer, skip the markers for the object (default layer has attribution, markers dont)
      if(layer.options.attribution == undefined) return;
      map.removeLayer(layer)
    })
    let layers = this.mapService.getBaseLayers();
    L.control.layers(layers).addTo(map);

    let selectedLayer = this.mapService.getSelectedLayer();
    if(selectedLayer == undefined || layers[selectedLayer] == undefined) {
      selectedLayer = "OpenStreetMap";
    }
    layers[selectedLayer].addTo(map);

    let mapService = this.mapService
    map.on('baselayerchange', function(e) {
      mapService.saveLayer(e.name);
    });
  }


  /**
   * Show object with all details on map
   */
  showObjectOnMap() {
    //Show all information on the map
    delete this.markers;
    this.markers = [];
    var ao = this.alarmObject;

    if (angular.isUndefined(ao.address.coords) || ao.address.coords === null) {
      this.$log.info('cannot show overall data');
      return;
    }

    //BMZ
    if (ao.bma !== null && angular.isDefined(ao.bma) && angular.isDefined(ao.bma.bmz) && angular.isDefined(ao.bma.bmz.coords)) {
      this.pushMarker({
        icon: this.getIcon('BMZ'),
        message: ao.bma.bmz.note
      }, ao.bma.bmz.coords);
    }
    //FSD
    if (ao.bma !== null && angular.isDefined(ao.bma) && angular.isDefined(ao.bma.fsd) && angular.isDefined(ao.bma.fsd.coords)) {
      this.pushMarker({
        icon: this.getIcon('FSD'),
        message: ao.bma.fsd.note
      }, ao.bma.fsd.coords);
    }

    //FIZ
    if (ao.bma !== null && angular.isDefined(ao.bma) && angular.isDefined(ao.bma.fiz) && angular.isDefined(ao.bma.fiz.coords)) {
      this.pushMarker({
        icon: this.getIcon('FIZ'),
        message: ao.bma.fiz.note
      }, ao.bma.fiz.coords);
    }

    //SPZ
    if (ao.bma !== null && angular.isDefined(ao.bma) && angular.isDefined(ao.bma.spz) && angular.isDefined(ao.bma.spz.coords)) {
      this.pushMarker({
        icon: this.getIcon('SPZ'),
        message: ao.bma.spz.note
      }, ao.bma.spz.coords);
    }
    //SchlÃ¼sselrohr
    if (ao.bma !== null && angular.isDefined(ao.bma) && angular.isDefined(ao.bma.keyTube) && angular.isDefined(ao.bma.keyTube.coords)) {
      this.pushMarker({
        icon: this.getIcon('keyTube'),
        message: ao.bma.keyTube.note
      }, ao.bma.keyTube.coords);
    }

    if (ao.bma !== null && angular.isDefined(ao.bma)) {
      //Extinguishes
      for (var i = 0; i < ao.bma.extinguishes.length; i++) {
        var extinguish = ao.bma.extinguishes[i];
        if (angular.isDefined(extinguish.coords)) {
          this.pushMarker({
            icon: this.getIcon('EXTINGUISH_' + extinguish.type),
            message: extinguish.location + ' - ' + extinguish.note
          }, extinguish.coords);
        }
      }

      //Elevators
      for (var i = 0; i < ao.bma.elevators.length; i++) {
        var elevator = ao.bma.elevators[i];
        if (angular.isDefined(elevator.coords)) {
          this.pushMarker({
            icon: this.getIcon('ELEVATOR'),
            message: elevator.access + ' - ' + elevator.note
          }, elevator.coords);
        }
      }

      //Ventilation
      for (var i = 0; i < ao.bma.ventilations.length; i++) {
        var ventilation = ao.bma.ventilations[i];
        if (angular.isDefined(ventilation.coords)) {
          this.pushMarker({
            icon: this.getIcon('VENTILATION_' + ventilation.type),
            message: ventilation.location + ' - ' + ventilation.note
          }, ventilation.coords);
        }

      }
    }

    //Hints
    for (var i = 0; i < ao.hints.length; i++) {
      var hint = ao.hints[i];

      let type = hint.type;
      if(type === 'STAGING_AREA' && hint.stagingRoomType === 'AIR') {
        type = type + '_AIR';
      }
      this.pushMarker({
        icon: this.getIcon(type),
        message: hint.note
      }, hint.coords);
    }

    //Building
    for (var i = 0; i < ao.buildings.length; i++) {
      var building = ao.buildings[i];
      if (angular.isUndefined(building.coords)) {
        continue;
      }

      this.pushMarker({
        icon: this.getIcon('BUILDING'),
        message: building.name
      }, building.coords);

    }

    //Locking Systems
    for (var i = 0; i < ao.lockingSystem.length; i++) {
      let lockingSystem = ao.lockingSystem[i];
      this.pushMarker({
        icon: this.getIcon('LOCKING_SYSTEM'),
        message: `${lockingSystem.type}
        <br>
        ${lockingSystem.hint}
        <br>
        ${lockingSystem.placeOfInstallation}`
      }, lockingSystem.coordinates);
    }

    //Access Gates
    for (var i = 0; i < ao.address.accessGates.length; i++) {
      var ag = ao.address.accessGates[i];

      this.pushMarker({
        icon: this.getIcon('ACCESS_GATE_' + ag.type),
        message: ag.street
      }, ag.coords);
    }

    //Danger
    if (angular.isDefined(ao.risks)) {
      //Substances
      for (var i = 0; i < ao.risks.substances.length; i++) {
        var substance = ao.risks.substances[i];
        if (angular.isDefined(substance.coords) && angular.isDefined(substance.coords.lat)) {

          this.pushMarker({
            icon: this.getIcon(substance.riskClass),
            message: substance.type
          }, substance.coords);
        }
      }
      //Objects
      for (var i = 0; i < ao.risks.objects.length; i++) {
        var o = ao.risks.objects[i];
        if (angular.isDefined(o.coords) && angular.isDefined(o.coords.lat)) {
          this.pushMarker({
            icon: this.getIcon('DANGER'),
            message: o.type
          }, o.coords);
        }
      }
    }

    // Paths
    for (var i = 0; i < ao.paths.length; i++) {
      var path = ao.paths[i];
      if (path.pathType === 'POINT') {
        // Only single point
        this.pushMarker({
          icon: this.getPathIcon(path),
          message: path.name
        }, path.pathShape[0]);
      } else if (path.pathType === 'POLYGON') {
        // Polygon
        var polygon = {
          color: path.pathStrokeColor,
          weight: path.pathStrokeWeight,
          opacity: path.pathStrokeOpacity,
          fillColor: path.fillColor,
          type: 'polygon',
          fillOpacity: path.fillOpacity,
          smoothFactor: path.smoothFactor,
          dashArray: this.helperService.getDashArray(path.pathStyle, path.pathStrokeWeight),
          latlngs: []
        };

        for (var ii = 0; ii < path.pathShape.length; ii++) {
          var point = path.pathShape[ii];
          polygon.latlngs.push({
            lat: point.lat,
            lng: point.lng
          });
        }
        this.paths.push(polygon);

        // Start and end marker
        if (angular.isDefined(path.startIcon) && path.startIcon !== null && path.startIcon !== '') {
          this.pushMarker({
            icon: this.getPathStartEndIcon(path, true),
            message: path.name
          }, path.pathShape[0]);
        }

      } else if (path.pathType === 'LINE') {
        // Polyline
        var polyline = {
          color: path.pathStrokeColor,
          weight: path.pathStrokeWeight,
          opacity: path.pathStrokeOpacity,
          smoothFactor: path.smoothFactor,
          dashArray: this.helperService.getDashArray(path.pathStyle, path.pathStrokeWeight),
          latlngs: []
        };

        for (var ii = 0; ii < path.pathShape.length; ii++) {
          var point = path.pathShape[ii];
          polyline.latlngs.push({
            lat: point.lat,
            lng: point.lng
          });
        }
        this.paths.push(polyline);

        // Start and end marker
        if (angular.isDefined(path.startIcon) && path.startIcon !== null && path.startIcon !== '') {
          this.pushMarker({
            icon: this.getPathStartEndIcon(path, true),
            message: path.name
          }, path.pathShape[0]);
        }

        if (angular.isDefined(path.endIcon) && path.endIcon !== null && path.endIcon !== '') {
          this.pushMarker({
            icon: this.getPathStartEndIcon(path, false),
            message: path.name
          }, path.pathShape[path.pathShape.length - 1]);
        }
      }
    }


    //Building marker
    this.markers.push({
      lat: ao.address.coords.lat,
      lng: ao.address.coords.lng
    });

    this.center = {
      lat: ao.address.coords.lat,
      lng: ao.address.coords.lng,
      zoom: 18
    };
  }


  /**
   * Get icon for path
   * @param {*} type
   */
  getPathIcon(path) {
    if (angular.isDefined(path.startIcon) && path.startIcon !== '') {
      return {
        iconUrl: this.restService.getBaseUrl() + '/objects/icons/' + path.startIcon + '?Authorization=' + this.restService.getAuthHeader(),
        iconSize: path.startIconDimension,
        iconAnchor: [path.startIconDimension[0] / 2, path.startIconDimension[1] / 2]
      };
    } else {
      // Use default icon
      return {
        iconUrl: 'img/static/downloadicon.png',
        iconSize: [32, 37],
        iconAnchor: [16, 37]
      };
    }
  };

  /**
   * Get icon for path
   * @param {*} type
   */
  getPathStartEndIcon(path, isStart) {
    if (isStart) {
      return {
        iconUrl: this.restService.getBaseUrl() + '/objects/icons/' + path.startIcon + '?Authorization=' + this.restService.getAuthHeader(),
        iconSize: path.startIconDimension,
        iconAnchor: [path.startIconDimension[0] / 2, path.startIconDimension[1] / 2]
      };
    } else {
      return {
        iconUrl: this.restService.getBaseUrl() + '/objects/icons/' + path.endIcon + '?Authorization=' + this.restService.getAuthHeader(),
        iconSize: path.endIconDimension,
        iconAnchor: [path.endIconDimension[0] / 2, path.endIconDimension[1] / 2]
      };
    }
  };


  /**
   * Get icon for marker type
   * @param {*} type
   */
  getIcon(type) {
    if (type === 'ADDRESS') {
      return undefined;
    } else if (type === 'BMZ') {
      return {
        iconUrl: 'img/static/bmz.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'LOCKING_SYSTEM') {
      return {
        iconUrl: 'img/static/lockingSystem.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'FSD') {
      return {
        iconUrl: 'img/static/fsd.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'FIZ') {
      return {
        iconUrl: 'img/static/fiz.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'SPZ') {
      return {
        iconUrl: 'img/static/spz.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'keyTube') {
      return {
        iconUrl: 'img/static/key.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'MEETING_POINT') {
      return {
        iconUrl: 'img/static/sammelpunkt.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'STAGING_AREA') {
      return {
        iconUrl: 'img/static/bereitstellung.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'STAGING_AREA_AIR') {
      return {
        iconUrl: 'img/static/bereitstellungLuft.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'AVAILABILITY_AREA') {
      return {
        iconUrl: 'img/static/verfÃ¼gungsraum.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'RMHP') {
      return {
        iconUrl: 'img/static/rettungsmittelhalteplatz.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'TREATMENT_PLACE') {
      return {
        iconUrl: 'img/static/behandlungsplatz.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'LOADING_ZONE') {
      return {
        iconUrl: 'img/static/ladezone.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'PATIENT_POINT') {
      return {
        iconUrl: 'img/static/patientenablage.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'LADDER_POINT') {
      return {
        iconUrl: 'img/static/ladder.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'CARE_POINT') {
      return {
        iconUrl: 'img/static/betreuungsstelle.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'CONTACT_POINT') {
      return {
        iconUrl: 'img/static/anlaufstelle.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'SPECIAL_ACCESS') {
      return {
        iconUrl: 'img/static/zufahrt.jpg',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'ACCESS_GATE_PRIMARY') {
      return {
        iconUrl: 'img/static/triangle.png',
        iconSize: [24, 20],
        iconAnchor: [12, 10]
      };
    } else if (type === 'ACCESS_GATE_SECONDARY') {
      return {
        iconUrl: 'img/static/triangle.png',
        iconSize: [14, 10],
        iconAnchor: [7, 5]
      };
    } else if (type === 'DANGER') {
      return {
        iconUrl: 'img/static/danger.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'BUILDING') {
      return {
        iconUrl: 'img/static/conference.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type === 'ELEVATOR') {
      return {
        iconUrl: 'img/static/elevator.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type.startsWith('EXTINGUISH_')) {
      var ico = 'img/static/extinguish/water-small.png';
      if (type.endsWith('WATER')) {
        ico = 'img/static/extinguish/water-small.png';
      } else if (type.endsWith('CO2')) {
        ico = 'img/static/extinguish/co2-small.png';
      } else if (type.endsWith('XENON')) {
        ico = 'img/static/extinguish/co2-small.png';
      } else if (type.endsWith('FOAM')) {
        ico = 'img/static/extinguish/foam-small.png';
      } else if (type.endsWith('SAND')) {
        ico = 'img/static/extinguish/powder-small.png';
      } else if (type.endsWith('INTERTING')) {
        ico = 'img/static/extinguish/co2-small.png';
      } else if (type.endsWith('FEED')) {
        ico = 'img/static/extinguish/feed-small.png';
      } else if (type.endsWith('ARGON')) {
        ico = 'img/static/extinguish/co2-small.png';
      }
      return {
        iconUrl: ico,
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else if (type.startsWith('VENTILATION_')) {
      var ico = 'img/static/ventilation/rwa-small.png';
      if (type.endsWith('RWA')) {
        ico = 'img/static/ventilation/rwa-small.png';
      } else if (type.endsWith('MECHANICAL')) {
        ico = 'img/static/ventilation/mechanical-small.png';
      } else if (type.endsWith('NATURAL')) {
        ico = 'img/static/ventilation/natural-small.png';
      }
      return {
        iconUrl: ico,
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    } else {
      // Risk class
      const ico = this.helperService.getIconForRiskClass(type);
      if (angular.isUndefined(ico)) {
        return undefined;
      }
      return {
        iconUrl: 'img/static/classes/' + ico + '.png',
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    }


  };


  /**
   * Update marker on map
   */
  updateMarkerOnMap() {
    this.markers = [];

    if (angular.isDefined(this.coords) && angular.isDefined(this.coords.lat)) {
      this.markers.push({
        lat: this.coords.lat,
        lng: this.coords.lng,
        draggable: true
      });
    }
  };

  pushMarker(marker, coords) {
    if (coords && coords.showOnMap) {
      marker.lat = coords.lat;
      marker.lng = coords.lng;
      this.markers.push(marker);
    }
  };
}
