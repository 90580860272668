'use strict';

import angular = require('angular');
export default class AvailabilityTablesComponent {
	public restrict: any;
	public scope: any;
	public template: any;
	public controller: any;
	public controllerAs: any;
	public bindToController: any;

  constructor() {
    this.restrict = 'E',
      this.scope = {
        onlineservice: '=',
        settings: '='
      }
    this.template = require('./table.component.html');

    this.controller = AvailabilityTablesController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//availabilityTable
/* @ngInject */
class AvailabilityTablesController {
	public $scope: any;
	public $rootScope: any;
	public $log: any;
	public helperService: any;
	public persons: any;
	public listeners: any;
	public settings: any;
	public functions: any;
	public onlineservice: any;

  constructor($scope, $rootScope, $log, helperService) {

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.helperService = helperService;

    this.persons = [];
    this.listeners = [];

    this.initListeners();

  }

  initListeners() {
    this.listeners.push(this.$rootScope.$on('availability_filtered', (event, data) => {
      this.settings = data;
      this.createTable();
    }));

    this.listeners.push(this.$scope.$watch('ctrl.onlineservice', (nVal) => {
      if (angular.isUndefined(nVal)) {
        return;
      }
      //Watch for changes
      this.createTable();
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  /**
   * Create table for UI
   */
  createTable() {
    this.$log.debug('Updating functions table...');
    //Reset
    this.functions = {};
    this.persons = [];

    for (var i = 0; i < this.onlineservice.data.lstOfAvailabilities.length; i++) {
      var person = this.onlineservice.data.lstOfAvailabilities[i];

      // Check filtered availability
      if (this.settings.filteredAvailability.includes(person.state)) {
        //Availability filtered
        continue;
      }
      this.persons.push(person);

      var excludedFunctions = [];
      if (angular.isDefined(person.excludedFunctions)) {
        excludedFunctions = person.excludedFunctions;
      }

      var functions = person.functions.split(';');
      for (var ii = 0; ii < functions.length; ii++) {
        var func = functions[ii];

        this.functions[func] = 0;

        if (excludedFunctions.indexOf(func) !== -1) {
          // This function is excluded
          continue;
        }

        //Update function counter
        if (person.state === 'AVAILABLE') {
          if (angular.isUndefined(this.functions[func])) {
            this.functions[func] = 1;
          } else {
            this.functions[func]++;
          }
          continue;
        }
      }
    };
  }

  /**
   * True, wenn die person die entsprechende Funktion enthÃ¤lt
   */
  hasFunction(person, func) {
    if (person.functionsAsList.indexOf(func) !== -1) {
      if (angular.isDefined(person.excludedFunctions)) {
        return person.excludedFunctions.indexOf(func) === -1 ? true : false;
      } else {
        return true;
      }
    }
    return false;
  }
}