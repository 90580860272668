'use strict';

import {ILogService, IRootScopeService, IScope, ITimeoutService, IWindowService} from "angular";
import {Person, PersonsAddressbookResponse} from "../../../data/person.data";
import RestService from "../../../services/rest.service";
import {UserAccount} from "../../../data/account.data";
import PrivilegeService from "../../../services/privilege.service";
import {RolePrivilege} from "../../../data/privileges.enum";
import angular = require("angular");

require('./addressbook.view.component.css');

const KEY_PAGE_SIZE = 'addressbook.page.size';

/* @ngInject */
export default class AddressbookController {
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public $scope: IScope;
  public $timeout: ITimeoutService;
  public $state: any;
  public $uibModal: any;
  public dataService: any;
  public restService: RestService;
  public $window: IWindowService;
  public $http: any;
  public helperService: any;
  public FileUploader: any;
  public listeners = [];
  public activeTab: number = 0;
  public account: UserAccount;
  public params: SortParams;
  public color: string;
  public addressbook: PersonsAddressbookResponse;
  public currentNbrOfPersonsAsPercentage: any;

  public priv: PrivilegeService;
  public hasAddr: boolean = false;
  public hasPers: boolean = false;
  public hasPersCreate: boolean = false;
  public hasAddrExport: boolean = false;

  public hasRole: boolean = false;

  public hasAvail: boolean = false;

  public hasAlarmGroups: boolean = false;
  public hasGeofence: boolean = false;
  public hasProv: boolean = false;
  public hasAM4: boolean = false;
  public hasAMobile: boolean = false;
  public hasLocation: boolean = false;
  public hasOverview: boolean = false;
  public hasAddrOnlines: boolean = false;
  public hasIOprint: boolean = false;

  public isFirstTimeLoading: boolean = true;
  public isLoading: boolean = false;

  private previousSearchFilter: string = '';

  constructor($rootScope: IRootScopeService, $scope: IScope, $state, $timeout: ITimeoutService, $log: ILogService, $window: IWindowService, $http, $uibModal, restService: RestService, dataService, helperService, FileUploader, privilegeService: PrivilegeService) {
    this.$log = $log;
    this.$log.debug('AddressbookController started...');
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;
    this.$uibModal = $uibModal;
    this.dataService = dataService;
    this.restService = restService;
    this.$timeout = $timeout;
    this.$window = $window;
    this.$http = $http;
    this.helperService = helperService;
    this.FileUploader = FileUploader;
    this.priv = privilegeService;

    this.initListeners();

    if (this.dataService.hasAccount()) {
      this.init();
    }else {
      // otherwise loading animation will be displayed
      this.isFirstTimeLoading= false;
    }
  }

  init() {
    this.hasPersCreate = this.priv.has(RolePrivilege.Addressbook_Persons_Create);
    this.hasAddrExport = this.priv.has(RolePrivilege.Addressbook_Export);
    this.hasAddrOnlines = this.priv.has(RolePrivilege.Addressbook_OnlineService);
    this.hasAddr = this.priv.has(RolePrivilege.Addressbook);
    this.hasRole = this.priv.has(RolePrivilege.User_Roles);
    this.hasPers = this.priv.has(RolePrivilege.Addressbook_Persons);
    this.hasAvail = this.priv.has(RolePrivilege.Availability);
    this.hasAlarmGroups= this.priv.has(RolePrivilege.Addressbook_AlarmGroup);
    this.hasGeofence = this.priv.has(RolePrivilege.Addressbook_Geofences);
    this.hasProv = this.priv.has(RolePrivilege.Addressbook_Provisioning);
    this.hasAM4 = this.priv.has(RolePrivilege.Addressbook_AM4);
    this.hasAMobile= this.priv.has(RolePrivilege.Addressbook_aMobile);
    this.hasLocation = this.priv.has(RolePrivilege.Addressbook_Location);
    this.hasOverview = this.priv.has(RolePrivilege.Addressbook_Overview);
    this.hasIOprint = this.priv.has(RolePrivilege.Addressbook_IOPrint_Read);


    this.$log.debug('Adressbook: Init AddressbookController...');
    this.account = this.dataService.getAccount();
    this.params = {
      sortType: 'displayName', // set the default sort type
      sortReverse: false, // set the default sort order
      searchFilter: '', // set the default search/filter term
      currentPage: 0,
      totalElements: 0,
      pageSize: 25
    } as SortParams;
    this.color = '#FE2E2E';


    var pageSize = localStorage.getItem(KEY_PAGE_SIZE);
    if (pageSize) {
      this.params.pageSize = Number.parseInt(pageSize);
    }

    setTimeout(() => this.onTabChange(this.activeTab), 500);
    //Load data
    if (this.hasPers){
      this.loadAddressbook();
    }
  }

  hasPendingPerson():boolean{
    if (!this.addressbook){
      return false;
    }
    if (!this.addressbook.pendingPersons){
      return false;
    }
    if(this.addressbook.pendingPersons.length>0){
      return true;
    }
    return false;
  }

  /**
   * Change the page
   * @returns A promise which resolves after changing the page
   */
  pageChanged(fromPageSelection?: boolean) {
    localStorage.setItem(KEY_PAGE_SIZE, this.params.pageSize + '');
    return new Promise<void>((resolve, reject) => {
      let searchPage = this.params.currentPage === 0 ? 0 : this.params.currentPage - 1;

      // Check filtering only if page selection did not change
      if (!fromPageSelection) {
        if (this.params.searchFilter !== this.previousSearchFilter) {
          /* when seach filter is different from previous we reset page
          */
          searchPage = 0;
          // save the current search filter
          this.previousSearchFilter = this.params.searchFilter;
        } else if (this.params.searchFilter.length > 0) {
          this.$log.info('Suchfilter hat sich nicht geÃ¤ndert');
          return;
        }
      }
      this.restService.loadPersons(searchPage,
        this.params.searchFilter,
        this.params.sortReverse ? 'DESC' : 'ASC',
        this.params.pageSize,
        undefined,
        false)
        .then((result: PersonsAddressbookResponse) => {
          this.addressbook = result;
          this.updateUI();
        }).finally(() => {
          this.isLoading = false;
          this.$scope.$applyAsync();
          var search = document.getElementById('addressbook-person-search');
          if (search) {
            this.$timeout(function () {
              search.focus();
            });
          }
          resolve();
        });
    });
  }

  /**
   * Load the saved addressbook from the server
   */
  loadAddressbook() {
    this.$log.info('Adressbook: Loading addressbook...');

    this.pageChanged().then(() => {
      this.isFirstTimeLoading = false;
    });

  };

  /**
   * Export addressbook to csv
   */
  exportAddressbook() {
    this.$window.open(
      this.restService.getBaseUrl() +
      '/addressbook/download?Authorization=' +
      this.$http.defaults.headers.common.Authorization,
      '_blank'
    );
  };

  /**
   * Export addressbook as pdf
   */
  exportAddressbookAsPdf() {
    this.$window.open(
      this.restService.getBaseUrl() +
      '/addressbook/export?Authorization=' +
      this.$http.defaults.headers.common.Authorization,
      '_blank'
    );
  };

  /**
   * Import addressbook from csv
   */
  importAddressbook() {
    var modalInstance = this.$uibModal.open({
      template: require('../../modals/addressbook/import.addressbook.modal/import.addressbook.modal.html'),
      controller: 'ImportAddressbookModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        uploader: () => {
          let fileUploader = new this.FileUploader();
          return fileUploader;
        }
      }

    });

    modalInstance.result.then(() => {
      //Reload
      this.loadAddressbook();
      this.$rootScope.$emit('imported.addressbook');
    });
  };

  exportPersonPluginAssignments() {
    this.$window.open(
      this.restService.getBaseUrl() +
      '/addressbook/export/plugins?Authorization=' +
      this.$http.defaults.headers.common.Authorization,
      '_blank'
    );
  }

  /**
   * Open modal for information about the self register portal
   */
  openSelfRegister() {
    this.$uibModal.open({
      template: require('../../modals/addressbook/self.registration.modal/self.registration.modal.html'),
      controller: 'SelfRegistrationModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'md'
    });
  };

  /**
      Add a new person to the adressbook
    */
  addPerson() {
    this.$uibModal.open({
      template: require('../../modals/addressbook/create.person.modal/create.person.modal.html'),
      controller: 'CreatePersonModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'sm',
      resolve: {
        okFunction: () => {
          return (data) => {
            this.isLoading = true;

            //REST call
            this.dataService.addPerson(
              data.displayName,
              data.lastName,
              data.firstName,
              undefined,
              undefined,
              (person) => {
                this.isLoading = false;

                this.resetSearchAndReload();

                this.$uibModal.open({
                  template: require('../../modals/addressbook/edit.person.modal/edit.person.modal.html'),
                  controller: 'EditPersonModalController',
                  controllerAs: 'ctrl',
                  backdrop: 'static',
                  size: 'lg',
                  resolve: {
                    person: person,
                    openedAsAdmin: false,
                    isNew: true
                  }
                });
              },
              (response) => {
                //Error occured
                this.isLoading = false;
                this.$log.error(response);
              }
            );
          };
        }
      }
    });
  };

  /**
   * Open the functions and groups overview modal
   */
  openFunctionsGroups() {
    this.$uibModal.open({
      template: require('../../modals/availability/functions.groups.modal/functions.groups.modal.html'),
      controller: 'FunctionsGroupsModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg'
    });
  };

  /**
   * Open Roles
   */
  openRoles() {
    this.$uibModal.open({
      template: require('../../modals/addressbook/roles/roles.html'),
      controller: 'RolesModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'hu'
    });
  }

  //#####
  //MISC
  //#####


  //Pagination
  setCurrentPage(currentPage: number) {
    this.params.currentPage = currentPage;
  };

  getNumberAsArray(num: number) {
    return new Array(num);
  };

  /**
   * Update the UI:
   * - current number of persons
   * - create person array for iterating in table
   */
  updateUI() {
    if (angular.isUndefined(this.addressbook)) {
      return;
    }
    this.params.totalElements = this.addressbook.totalElements;
    this.params.totalPages = this.addressbook.totalPages;

    // Check state
    if (this.$state.is('main.users.groups')) {
      this.activeTab = 1;
    }
  };

  //#### EVENTS ####
  triggerLoadOverviewEvent() {
    //Emit load event
    this.activeTab = 6;
    this.$rootScope.$broadcast('load.overview');
  };

  /**
   * Broadcasts an event to load all locations for given persons
   */
  triggerLoadLocations() {
    this.$rootScope.$broadcast('load.person.locations');
  };


  /**
   * Init event listeners
   */
  initListeners() {
    //Wait for new account
    this.listeners.push(
      this.$rootScope.$on('new.account', () => {
        //Init controller
        this.init();
      })
    );

    //Wait for LOGOUT
    this.listeners.push(
      this.$rootScope.$on('delete.account', () => {
        this.account = undefined;
      })
    );

    //Wait for person deleted
    this.listeners.push(
      this.$rootScope.$on('delete.person', () => {
        this.resetSearchAndReload();
      })
    );

    //Adressbook changed
    this.listeners.push(
      this.$rootScope.$on('change.addressbook', () => {
        this.$log.info('addressbook.view.component: change.addressbook');
        this.resetSearchAndReload();
      })
    );

    //Person changed
    this.listeners.push(
      this.$rootScope.$on('update.person', (event, person: Person) => {
        this.pageChanged();
      })
    );

    this.listeners.push(this.$rootScope.$on('tab.change.addressbook', (event, data) => {
      this.onTabChange(data);
    }))

    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  onTabChange(data: number) {
    if (data >= 0 && data <= 8) {

      if (this.checkActiveTabPermission(data)) {
        this.activeTab = data;
        if (data === 5) {
          this.triggerLoadLocations();
        } else if (data === 6) {
          this.triggerLoadOverviewEvent();
        }
        this.$rootScope.$emit('addressbook.view.tab', data);
      } else {
        this.switchToNextAllowedTab();
      }
    }
  }

  checkActiveTabPermission(tabIndex: number): boolean {
    switch (tabIndex) {
      case 0:
        return this.hasPers;
      case 1:
        return this.hasAlarmGroups;
      case 2:
        return this.hasAM4;
      case 3:
        return this.hasAMobile;
      case 4:
        return this.hasProv;
      case 5:
        return this.hasLocation;
      case 6:
        return this.hasOverview;
      case 7:
        return this.hasGeofence;
      case 8:
        return this.hasIOprint;
      default:
        return false;

    }
  }

  switchToNextAllowedTab() {
    if (this.hasPers) {
      this.onTabChange(0);
    } else if (this.hasAlarmGroups) {
      this.onTabChange(1);
    } else if (this.hasAM4) {
      this.onTabChange(2);
    } else if (this.hasAMobile) {
      this.onTabChange(3);
    } else if (this.hasProv) {
      this.onTabChange(4);
    } else if (this.hasLocation) {
      this.onTabChange(5);
    } else if (this.hasOverview) {
      this.onTabChange(6);
    } else if (this.hasGeofence) {
      this.onTabChange(7);
    }
  }

  /**
   * Reset the search filter
   * */
  resetSearchAndReload() {
    this.params.searchFilter = '';
    this.previousSearchFilter = '';
    this.params.currentPage = 0;
    this.pageChanged();
  };
}

export interface SortParams {
  sortType: string,
  sortReverse: boolean,
  searchFilter: string,
  currentPage: number,
  pageSize: number,
  totalElements: number,
  totalPages: number
}
