'use strict'

import { IRootScopeService, IScope, IIntervalService } from "angular";
import { Queue, QueueEntryResponse, QueueTableResponse } from "../../../data/queue.data";
import { DWDWeather, DWDWeatherWarning, PegelWarning } from "../../../data/weather.data";
import RestService from "../../../services/rest.service";
import * as L from "leaflet";
import MapService from "../../../services/map.service";
import { UserAccount } from "../../../data/account.data";
import HelperService from "../../../services/helper.service";
import {MetaInformation} from '../../../data/metaInformation.data';

require('./queues.view.component.scss');

/* @ngInject */
export default class QueuesController {
  public isLoading = false;
  private listeners = [];
  private queues: Queue[] = [];
  private queueTable: QueueTableResponse;
  private filteredQueueTable: QueueTableResponse = {} as QueueTableResponse;

  public summary: Summary = {
    nbrOfQueues: 0
  };

  private clockTicker;
  private weatherTicker;
  private wmsRainTicker;
  public clock: Date = new Date();
  private weather: DWDWeather;
  public warnings: DWDWeatherWarning[] = [];
  public pegelWarnings: PegelWarning[] = [];

  public map;
  public radarOpened = false;
  public rainRadarLayer: L.TileLayer.WMS;

  public displayFinished = false;
  public displayRunning = false;
  public displayAssigned = false;
  public displayEmptyColumn = false;
  public columnWrapMode = false;
  public onlyAssignedVehicles = false;
  public displayMode: QueueDisplayMode = QueueDisplayMode.COLUMN;

  public displayPrio1 = true;
  public displayPrio2 = true;
  public displayPrio3 = true;
  public displayPrio4 = true;

  constructor(private $scope: IScope,
    private $rootScope: IRootScopeService,
    private restService: RestService,
    private $interval: IIntervalService,
    private dataService,
    public mapService: MapService,
    public helperService: HelperService) {
    this.initListeners();
    // Initial load
    this.load();
    this.loadWeatherAndWarnings();
  }


  load() {
    this.displayFinished = this.helperService.getFromStorage('QUEUE.DISPLAY_FINISHED', false);
    this.displayRunning = this.helperService.getFromStorage('QUEUE.DISPLAY_RUNNING', true);
    this.displayAssigned = this.helperService.getFromStorage('QUEUE.DISPLAY_ASSIGNED', true);

    this.displayEmptyColumn = this.helperService.getFromStorage('QUEUE.DISPLAY_EMPTY', false);
    this.onlyAssignedVehicles = this.helperService.getFromStorage('QUEUE.ONLY_ASSIGNED', false);
    this.columnWrapMode = this.helperService.getFromStorage('QUEUE.COLUMN_WRAP_MODE', false);
    this.displayMode = this.helperService.getFromStorage('QUEUE.DISPLAY_MODE', 'COLUMN') as QueueDisplayMode;
    this.isLoading = true;

    if (this.displayMode === QueueDisplayMode.COLUMN) {
      this.restService.loadQueues()
      .then(queues => this.queues = queues)
      .then(() => {
        this.summary = {
          nbrOfQueues: this.queues.length
        };
      }).finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
    } else {
      this.reloadTable();
    }
  }

  reloadTable() {
    if (this.displayMode === QueueDisplayMode.COLUMN) {
      return;
    }
    this.restService.loadQueuesAsTable(this.displayRunning, this.displayAssigned, this.displayFinished)
      .then(table => this.queueTable = table)
      .then(() => {
        this.summary = {
          nbrOfQueues: this.queueTable.numberOfQueues
        };
      })
      .then(() => this.filterQueueTable())
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
  }

  filterQueueTable() {
    this.filteredQueueTable.assigned = this.queueTable.assigned.filter(entry => this.filterQueueEntry(entry));
    this.filteredQueueTable.done = this.queueTable.done.filter(entry => this.filterQueueEntry(entry));
    this.filteredQueueTable.inProgress = this.queueTable.inProgress.filter(entry => this.filterQueueEntry(entry));
  }

  filterQueueEntry(entry: QueueEntryResponse) {
    switch (entry.priority) {
      case 1:
        return this.displayPrio1;
      case 2:
        return this.displayPrio2;
      case 3:
        return this.displayPrio3;
      case 4:
        return this.displayPrio4;
      default:
        return false;
    }
  }

  loadWeatherAndWarnings() {
    this.restService.loadWeather().then(currentWeather => {
      this.weather = currentWeather.weather;
    }).then(() => {
      this.restService.loadWeatherWarnings().then(warnings => {
        this.warnings = warnings.weatherWarnings;
        this.pegelWarnings = warnings.pegelWarnings;
      }).finally(() => this.$scope.$applyAsync());
    });
  }

  toggleColumnWrapMode() {
    this.columnWrapMode = !this.columnWrapMode;
    this.helperService.saveInStorage('QUEUE.COLUMN_WRAP_MODE', this.columnWrapMode);
  }

  toggleOnlyAssignedVehicles() {
    this.onlyAssignedVehicles = !this.onlyAssignedVehicles;
    this.helperService.saveInStorage('QUEUE.ONLY_ASSIGNED', this.onlyAssignedVehicles);
    this.$rootScope.$emit('queue.only_assigned', this.onlyAssignedVehicles);
  }

  toggleDisplayFinished() {
    this.displayFinished = !this.displayFinished;
    this.helperService.saveInStorage('QUEUE.DISPLAY_FINISHED', this.displayFinished);
    this.$rootScope.$emit('queue.show_finished', this.displayFinished);
    if (this.displayMode === QueueDisplayMode.TABLE) {
      // Trigger reload for table mode
      this.load();
    }
  }

  toggleDisplayRunning() {
    this.displayRunning = !this.displayRunning;
    this.helperService.saveInStorage('QUEUE.DISPLAY_RUNNING', this.displayRunning);
    this.load();
  }

  toggleDisplayAssigned() {
    this.displayAssigned = !this.displayAssigned;
    this.helperService.saveInStorage('QUEUE.DISPLAY_ASSIGNED', this.displayAssigned);
    this.load();
  }

  toggleDisplayEmptyColumn() {
    this.displayEmptyColumn = !this.displayEmptyColumn;
    this.helperService.saveInStorage('QUEUE.DISPLAY_EMPTY', this.displayEmptyColumn);
  }

  togglePriority(priority: number) {
    switch (priority) {
      case 1:
        this.displayPrio1 = !this.displayPrio1;
        break;
      case 2:
        this.displayPrio2 = !this.displayPrio2;
        break;
      case 3:
        this.displayPrio3 = !this.displayPrio3;
        break;
      case 4:
        this.displayPrio4 = !this.displayPrio4;
        break;
    }
    this.filterQueueTable();
  }

  selectQueueDisplayMode(mode: QueueDisplayMode) {
    this.displayMode = mode;
    this.helperService.saveInStorage('QUEUE.DISPLAY_MODE', this.displayMode);
    this.load();
  }

  /**
   * Toggle Radard opened button and initialize map if necessary
   */
  openRadardAndInitMap() {
    this.radarOpened = !this.radarOpened;

    if (this.radarOpened) {
      setTimeout(() => {
        let userAccount: UserAccount = this.dataService.getAccount() as UserAccount;
        L.Icon.Default.imagePath = '/img/static';

        const latLng = L.latLng(userAccount.settings.lat, userAccount.settings.lng);

        this.map = L.map('queue-weather-radar', { attributionControl: true, zoomControl: false }).setView(latLng, 10);
        let layers = this.mapService.getBaseLayers();
        let selectedLayer = this.mapService.getSelectedLayer();
        if (selectedLayer == undefined || layers[selectedLayer] == undefined) {
          selectedLayer = "OpenStreetMap";
        }
        layers[selectedLayer].addTo(this.map);

        // Rain radar
        this.rainRadarLayer = L.tileLayer.wms('https://maps.dwd.de/geoserver/dwd/wms', {
          maxZoom: 19,
          id: 'Regenradar',
          attribution: 'Â© Deutscher Wetterdienst',
          layers: 'dwd:Niederschlagsradar',
          format: 'image/png',
          transparent: true,
          opacity: 0.5,
          zIndex: 11
        });
        this.rainRadarLayer.addTo(this.map);

        // Start ticker
        this.wmsRainTicker = this.$interval(() => {
          // Force reload
          this.rainRadarLayer.setParams({} as L.WMSParams);
        }, 1000 * 60);

      }, 500);
    } else {
      if (this.wmsRainTicker) {
        this.$interval.cancel(this.wmsRainTicker);
      }
    }

  }

  initListeners() {
    this.listeners.push(this.$rootScope.$on('new.account', (event, account) => {
        this.load();
    }));
    this.listeners.push(this.$rootScope.$on('metainformation.change', (event, meta: MetaInformation) => {
      this.loadWeatherAndWarnings();
    }));
    this.listeners.push(this.$rootScope.$on('queue.QUEUE_ENTRY_ADDED', (event, data) => {
      this.reloadTable();
    }));
    this.listeners.push(this.$rootScope.$on('queue.QUEUE_ENTRY_REMOVED', (event, data) => {
      this.reloadTable();
    }));
    this.listeners.push(this.$rootScope.$on('queue.QUEUE_ENTRY_UPDATED', (event, data) => {
      this.reloadTable();
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
      this.$interval.cancel(this.clockTicker);
      this.$interval.cancel(this.weatherTicker);

      if (this.wmsRainTicker) {
        this.$interval.cancel(this.wmsRainTicker);
      }
    });

    this.clockTicker = this.$interval(() => {
      this.clock = new Date();
    }, 1000);


    this.weatherTicker = this.$interval(() => {
      // Load weather
      this.loadWeatherAndWarnings();

    }, 1000 * 60 * 30); // Every 30 Minutes
  }
}

interface Summary {
  nbrOfQueues: number;
}

enum QueueDisplayMode {
  TABLE = 'TABLE',
  COLUMN = 'COLUMN'
}